import { find, findAll } from "../utils/dom";

/** OBSERVAÇÃO: 
 * Algumas funcionalidades presentes neste arquivo foram reutilizadas do arquivo 'modalMenu.js'.
 * 
 */


const traducao = () => {


	const BASE_URL = `${window.location.protocol}//${window.location.hostname}${(window.location.port) ? ':' + window.location.port : ''}${(window.location.pathname) ? window.location.pathname : ''}`;

	console.log(BASE_URL);

	function switchText(text1, text2) {
		for (let i = 0; i < Math.min(text1.length, text2.length); i++) {
			text1[i].classList.add('hidden');
			text2[i].classList.remove('hidden');
		}
	}

	function switchLanguage(select, text1, text2) {
		if (select.value === 'pt') {
			document.documentElement.classList.add('lang-pt')
			document.documentElement.classList.remove('lang-en')
			switchText(text2, text1);
		} else if (select.value === 'en') {
			switchText(text1, text2);
			document.documentElement.classList.add('lang-en')
			document.documentElement.classList.remove('lang-pt')
		}
	}


	function getIdioma(searchURL) {
		const resultSearch = searchURL?.split("?")[1]?.split("&");
		const langBuffer = localStorage.getItem('urlValue') ?? undefined;

		const result = resultSearch?.find((value, index) => {
			if (value.includes('lang')) {
				return value;
			}
		});
		if (result) {
			return result.split("=")[1];
		}

		if (langBuffer) {
			return langBuffer;
		} else {
			return 'pt';
		}
	}

	function setIdioma(idioma, infoPage, select) {
		if (!idioma) return;
		let textPt = document.querySelectorAll(".text-pt");
		let textEn = document.querySelectorAll(".text-en");


		switch (idioma) {
			case 'en':
				infoPage.setAttribute('lang', 'en');
				localStorage.setItem('idiomaSelecionado', 'en');
				localStorage.setItem('urlValue', 'en');
				select.value = 'en';
				break;

			case 'pt':
				infoPage.setAttribute('lang', 'pt-br');
				localStorage.setItem('idiomaSelecionado', 'pt-br');
				localStorage.setItem('urlValue', 'pt');
				select.value = 'pt';
				break;

			default:
				infoPage.setAttribute('lang', 'pt-br');
				localStorage.setItem('idiomaSelecionado', 'pt-br');
				localStorage.setItem('urlValue', 'pt');
				select.value = 'pt';
				break;
		}

		switchLanguage(select, textPt, textEn);
	}

	/** Função que pesiste no slug do idioma
	 * Ex.: Se selecionar outra página, ela vai ser obrigatória adicionar esse slug lang no search dela.
	 */
	function persisteURLIdioma(select, infoPage) {
		if (!window.location.search.includes('lang')) {
			const idiomaAtual = localStorage.getItem('urlValue');
			if (!idiomaAtual) return;

			if (!window.location.search.includes('?')) {
				window.location.search += `lang=${idiomaAtual}`;
			} else {
				window.location.search += `&lang=${idiomaAtual}`;
			}


		}
	}


	// Função para iniciar o Drop de Idiomas.
	function initDropIdioma(searchURL, infoPage, select) {

		select.addEventListener("change", (e) => {
			if (searchURL.includes('lang=pt')) {

				const novoSearchURL = searchURL.replace('lang=pt', `lang=${e.target.value}`);
				setIdioma(e.target.value, infoPage, select);
				window.location.href = `${BASE_URL}${novoSearchURL}`;


			} else if (searchURL.includes('lang=en')) {

				const novoSearchURL = searchURL.replace('lang=en', `lang=${e.target.value}`);
				setIdioma(e.target.value, infoPage, select);
				window.location.href = `${BASE_URL}${novoSearchURL}`;


			}
		})

	}


	document.addEventListener("DOMContentLoaded", () => {
		const select = find('#language-select');


		const infoPage = find('html');

		const searchURL = window.location.search;
		const idioma = getIdioma(searchURL);


		setIdioma(idioma, infoPage, select);
		persisteURLIdioma(select, infoPage);

		initDropIdioma(searchURL, infoPage, select);
	})



}

export default traducao;